<template>
  <v-card class="pa-2" style="min-height: 90vh; max-height: 90vh; overflow-y: hidden" flat>
    <v-row class="mx-0 pb-1 align-center" style="height: 100px">
      <p class="pt-2" style="width: 20%">{{ resolveModalLabelByStepType() }}</p>
      <v-autocomplete
        v-model="customJSFunctionsValue"
        :items="customJSFunctions"
        :label="$lang.labels.customFunctions"
        outlined
        dense
        hide-details
        hide-no-data
        hide-selected
        class="ml-2"
        style="width: 20%"
        @change="addShortCode(customJSFunctionsValue)"
      />
      <v-autocomplete
        v-model="valueArrayValue"
        :items="valueArray"
        item-text="key"
        item-value="key"
        label="Variables"
        outlined
        dense
        hide-details
        hide-no-data
        hide-selected
        class="ml-2"
        style="width: 40%"
        @change="addShortCode(valueArrayValue)"
      />
      <v-btn
        icon
        color="primary"
        text
        large
        class="ml-2"
        @click="$emit('closeDialog', true)"
      >
        <v-icon dark size="20">mdi-close</v-icon>
      </v-btn>
    </v-row>
    <p class="pb-0 mb-1">{{ $lang.header.key }}</p>
    <div style="position: relative">
      <v-text-field
        ref="keyRef"
        v-model="key"
        outlined
        dense
        required
        :rules="[v => !!v || $lang.labels.required, ['REST', 'LDAP', 'I_CALENDAR'].includes(stepType) ? true : (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        class="required-asterisk"
        :readonly="!canEdit"
      ></v-text-field>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            text
            large
            v-bind="attrs"
            style="position: absolute; right: 0; top: 0; z-index: 9999; margin-right: 10px;"
            v-on="on"
            @click="copyField('key')"
          >
            <v-icon>mdi-vector-combine</v-icon>
          </v-btn>
        </template>
        <span>{{ $lang.labels.copy }}</span>
      </v-tooltip>
    </div>
    <p class="pb-0 mb-0">{{ $lang.header.value }}</p>
    <codemirror v-if="initDone" v-model="code" :options="cmOptions" @blur="handleBlurEditor"></codemirror>
    <v-card-title v-if="withButtons" class="pt-2">
      <v-btn
        color="primary"
        outlined
        class="ml-1 mb-1"
        min-width="100px"
        min-height="40px"
        @click="$emit('closeDialog', true)"
      >
        <v-icon left dark class="mr-1">
          mdi-cancel
        </v-icon>
        {{ $lang.actions.cancel }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        :disabled="!key || !code || (['REST', 'LDAP'].includes(stepType) ? false : !$options.filters.javaVariableConventionRules(key, true))"
        class="mr-1 mb-1"
        min-width="100px"
        min-height="40px"
        @click="save()"
      >
        <v-icon left dark class="mr-1">
          mdi mdi-floppy
        </v-icon>
        {{ $lang.actions.save }}
      </v-btn>
    </v-card-title>
    <v-snackbar
      v-model="snackShow"
      :color="snackColor"
      content-class="text-center"
      top
    >
      <span class="color-accent-text">{{ snackbarText }}</span>
    </v-snackbar>
  </v-card>
</template>

<script>
// require component
import { codemirror } from 'vue-codemirror'
import { JSHINT } from 'jshint'

// require styles
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/ayu-mirage.css'
import 'codemirror/theme/3024-day.css'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/hint/show-hint.css'

// import js
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/groovy/groovy.js'
import 'codemirror/mode/python/python.js'
import 'codemirror/addon/lint/lint.js'
import 'codemirror/addon/lint/javascript-lint.js'
import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/addon/hint/show-hint.js'

import '../../assets/scss/code-mirror.scss'
import copy from 'copy-to-clipboard'
import { defaultRestVariables } from '@/utils/constants'
import { recursion } from '@/utils/helpers'

export default {
  components: {
    codemirror
  },
  props: {
    vars: {
      type: Array,
      default: () => {
        return []
      }
    },
    item: {
      type: Object,
      default: () => {
        return {
          text: '',
          value: ''
        }
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    withButtons: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    singleStep: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data: () => (
    {
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      initDone: false,
      code: '',
      key: '',
      customJSFunctions: [
        'f_is_var_defined',
        'f_is_var_defined_and_not_null',
        'f_check_defined_and_not_null_default'
      ],
      cursorPosition: {
        line: 0,
        ch: 0
      },
      valueArray: [],
      customJSFunctionsValue: '',
      customFunctionsValue: '',
      valueArrayValue: '',
      cmOptions: {
        gutters: ['CodeMirror-lint-markers'],
        tabSize: 4,
        mode: 'javascript',
        theme: 'ayu-mirage',
        lineNumbers: true,
        line: true,
        lineWrapping: true,
        autocorrect: true,
        autocomplete: true,
        selfContain: true,
        highlightLines: true,
        viewportMargin: Infinity,
        matchBrackets: true,
        autoCloseBrackets: true,
        lint: true,
        showHints: true
      }
    }),
  watch: {
    code: {
      handler(val) {
        if (!this.withButtons) this.$emit('fromGlobalEditor', val)
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.item && !this.item.text) this.$refs.keyRef.focus()
    }, 200)
  },
  created() {
    window.JSHINT = JSHINT
    if (this.item) {
      this.code = this.item.value
      this.key = this.item.text
    }

    this.valueArray = this.recursion(this.vars, this.singleStep)

    const names = this.valueArray.map((o) => o.key)
    const filtered = this.valueArray.filter(({ key }, index) => !names.includes(key, index + 1))

    this.valueArray = filtered.sort((a, b) => a.key.localeCompare(b.key))

    this.valueArray = [...this.valueArray, ...defaultRestVariables]

    if (this.stepType === 'GROOVY') {
      this.cmOptions.mode = 'groovy'
      this.cmOptions.lint = false
      this.cmOptions.gutters = []
      this.cmOptions.selfContain = false
    } else if (this.stepType === 'PYTHON') {
      this.cmOptions.mode = 'python'
      this.cmOptions.lint = false
      this.cmOptions.gutters = []
      this.cmOptions.selfContain = false
    }

    if (this.$vuetify.theme.dark) {
      this.cmOptions.theme = 'ayu-mirage'
    } else {
      this.cmOptions.theme = '3024-day'
    }

    setTimeout(() => {
      this.initDone = true
    }, 300)
  },
  methods: {
    recursion,
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    copyField(fieldNAme) {
      if (this[fieldNAme]) {
        copy(this[fieldNAme])
        this.showSnack(this.$lang.success.copiedClipboard, 'success')
      } else {
        this.showSnack(this.$lang.errors.nothingToCopy, 'warning')
      }
    },
    resolveModalLabelByStepType() {
      if (this.$lang.status[this.stepType]) return this.$lang.status[this.stepType]

      return this.$lang.labels.code
    },
    handleBlurEditor(data) {
      const doc = data.getDoc()

      const cursor = doc.getCursor()

      const pos = {
        line: cursor.line,
        ch: cursor.ch
      }

      this.cursorPosition = pos
    },
    handleBlur(event) {
      this.cursorPosition = event.target.selectionStart
    },
    addShortCode(value) {
      const cm = document.querySelectorAll('.CodeMirror')[0].CodeMirror

      const position = this.cursorPosition.ch === 0 || this.cursorPosition ? this.cursorPosition : this.code.length

      const doc = cm.getDoc()

      doc.replaceRange(value, position) // adds a new line

      setTimeout(() => {
        this.customJSFunctionsValue = null
        this.customFunctionsValue = ''
        this.valueArrayValue = ''
      }, 5)
    },
    highlighter(code) {
      return highlight(code, languages.js) //returns html
    },
    save() {
      this.$emit('fromGlobalEditor', { key: this.key, value: this.code })
    }
  }
}
</script>
<style lang="scss" scoped>

@font-face {
  font-family: 'Fira code';
  src: local('fira code'), url('~@/assets/fonts/FiraCode-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Fira Mono';
  src: local('Fira Mono'), url('~@/assets/fonts/FiraMono-Regular.ttf') format('truetype');
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 12px;
  height: 50vh;
  overflow-y: auto;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}

.vue-codemirror {
  height: 55vh;
  width: 100%;
}
</style>
