<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialName`"
      step-type="LDAP"
      :properties="properties"
      step-label="ldap"
      :readonly="!canEdit"
      required
      @change="handleChange('credentialName', $event)"
    />

    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedLdapActions"
        :default-value="properties.action"
        step-label="ldap"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-dn`"
        v-model="properties.fields.dn"
        outlined
        dense
        :label="$lang.labels.dn"
        required
        :rules="['BIND', 'MODIFY'].includes(properties.action) ? [v => !!v || $lang.labels.required] : []"
        :readonly="!canEdit"
        :class="['BIND', 'MODIFY'].includes(properties.action) ? 'required-asterisk' : ''"
        data-cy="ldap-dn"
      />
    </v-col>

    <v-col v-if="properties.action === 'SEARCH'" cols="12">
      <v-text-field
        :key="`${step.id}-filter`"
        v-model="properties.fields.filter"
        outlined
        dense
        :label="$lang.labels.filter"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="ldap-filter"
      />
      <v-text-field
        :key="`${step.id}-searchKey`"
        v-model="properties.fields.searchKey"
        outlined
        dense
        :label="$lang.labels.searchKey"
        required
        :readonly="!canEdit"
        data-cy="ldap-searchKey"
      />
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.fields.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="ldap-targetObject"
      />
    </v-col>

    <v-col v-if="['MODIFY', 'BIND'].includes(properties.action)" cols="12" class="pb-2">
      <ldap-attributes
        :key="`${step.id}-attributeValues`"
        type="attributeValues"
        step-type="LDAP"
        :can-edit="canEdit"
        :data="properties.fields.attributeValues"
        :single-step="step"
        :steps="steps"
        @editor="$emit('openEditor', $event, 'fields.attributeValues')"
        @dataChanged="handleChange('fields.attributeValues', $event)"
      />
    </v-col>

    <v-col v-if="['BIND'].includes(properties.action)" cols="12" class="py-1">
      <add-vals
        :key="`${step.id}-objectClassAttributes`"
        type="objectClassAttributes"
        step-type="LDAP"
        :can-edit="canEdit"
        :data="properties.fields.objectClassAttributes"
        @dataChanged="handleChange('fields.objectClassAttributes', $event)"
      ></add-vals>
    </v-col>
  </v-row>
</template>

<script>
import LdapAttributes from '../LdapAttributesNameValuesList.vue'
import addVals from '../ValList'
import { ActionSelect, CredentialsSelect } from './components'
import StepMixin from './StepMixin'
import { castValueForStep } from '@/utils/helpers'
import { definitions } from '@/utils/definitions'

export default {
  name: 'LDAPStep',
  components: {
    LdapAttributes,
    addVals,
    ActionSelect,
    CredentialsSelect
  },
  mixins: [StepMixin],
  props: {
    steps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ldapActions: definitions.LdapStepProperties.properties.action.enum
    }
  },
  computed: {
    formattedLdapActions() {
      return this.ldapActions.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.action) this.properties.action = 'SEARCH'
    if (!this.properties.fields) {
      this.$set(this.properties, 'fields', {
        dn: '',
        filter: '',
        searchKey: '',
        targetObject: ''
      })
    }
  },
  methods: {
    handleChange(key, value) {
      this.$set(this.properties, key, castValueForStep(
        key,
        value,
        {
          shouldBeString: ['credentialName'] // Specify credentialName for casting
        }
      ))

      if (key === 'action') this.onLdapActionChange(value)
    },
    onLdapActionChange(val) {
      switch (val) {
      case 'SEARCH':
        this.$set(this.properties, 'fields', {
          dn: '',
          filter: '',
          searchKey: '',
          targetObject: ''
        })
        break
      case 'BIND':
        this.$set(this.properties, 'fields', {
          dn: '',
          objectClassAttributes: [],
          attributeValues: []
        })
        break
      case 'MODIFY':
        this.$set(this.properties, 'fields', {
          dn: '',
          attributeValues: []
        })
        break
      default:
        this.$set(this.properties, 'fields', {})
        break
      }
    }
  }
}
</script>
